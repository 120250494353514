@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");

:root {
  --color-black: #03203c;
  --color-dark-blue: #006283;
  --link-gray: #8b9ec2;
  --link-white: #ffffff;
  --link-blue: #00aeb4;
  --link-light: #dae6fc;
}
*,
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.col-12 {
  flex-basis: 100%;
}
.col-8 {
  flex-basis: 60%;
}
.col-6 {
  flex-basis: 45%;
}
.col-4 {
  flex-basis: 30%;
}
.col-3 {
  flex-basis: 23%;
}
.mb-3 {
  margin-bottom: 30px;
}
.mt-3 {
  margin-top: 30px;
}
.text-center {
  text-align: center;
}
.button-primary,
.button-secondary,
.button-blue,
.button-dark-blue {
  background-color: var(--color-black);
  display: inline-block;
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  border: none;
  cursor: pointer;
}
.button-secondary {
  background-color: var(--link-gray);
  color: var(--color-black);
}
.button-blue {
  background-color: var(--link-blue);
}
.button-dark-blue {
  background-color: var(--color-dark-blue);
}
a.cta-button:hover {
  transition: 0.3s ease-in-out;
  transform: scale(1.05);
}

img {
  width: 100%;
}
.form-control {
  background: #fff;
}
/* Drawer */
.btn.btn-primary.drawer-button {
  position: fixed;
  right: 0;
  top: 0;
}
.drawer-toggle ~ .drawer-side {
  height: 100vh;
}
/* Drawer Ends */

section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

section h1 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 30px;
  color: var(--color-black);
  font-family: "Oswald", sans-serif;
}
header {
  color: #fff;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--color-black);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
main {
  background-size: cover;
  overflow: hidden;
  background-color: #fff;
}

/* Header styles */
i.fa.fa-times {
  color: #fff;
  position: absolute;
  right: 6px;
  top: 4px;
  font-size: 16px;
}
i.fa.fa-bars {
  color: #fff;
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 15px;
}
header i.fa.fa-times,
.home .fa.fa-bars {
  display: none;
}
.mak-alamin {
  text-align: center;
}
.mak-alamin img {
  width: 100%;
  max-width: 180px;
  margin: 30px auto 5px;
  border-radius: 60%;
  border: 10px solid #03284bfc;
}
.mak-alamin h1 {
  color: #fff;
  font-size: 1.4rem;
}
nav {
  text-align: center;
}

nav ul li {
  list-style: none;
}
nav ul li a,
nav ul li label {
  cursor: pointer;
  display: block;
  padding: 10px;
  color: var(--link-gray);
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}
nav ul li a:hover,
nav ul li label:hover {
  color: #fff;
}
label.swap {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 999;
  background: #03203c;
}
svg.swap-off,
svg.swap-on {
  fill: #00aeb4;
  width: 24px;
  height: 24px;
}
.swap-rotate .swap-on,
.swap-rotate .swap-indeterminate,
.swap-rotate input:indeterminate ~ .swap-on {
  --tw-rotate: 90deg;
}
.swap .swap-on,
.swap .swap-indeterminate,
.swap input:indeterminate ~ .swap-on {
  opacity: 1;
}

header .hire-me {
  text-align: center;
  margin-top: 25px;
}
header .hire-me p,
header .hire-me p a {
  color: #fff;
  font-size: 15px;
}
header .hire-me img {
  max-width: 56px;
}
header .hire-me .marketplaces {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 8px;
}
header .hire-me .marketplaces a {
  background: #fefefe;
  display: inline-block;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 30px;
}
/* Header style ends */

/* Home Section */
section.home {
  height: 100vh;
}
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgb(3, 32, 60, 0.5);
  width: 100%;
  height: 100%;
}
.home h1 {
  font-size: 65px;
  font-weight: 500;
  color: var(--link-light);
  text-shadow: 0 0 50px #000;
  margin-bottom: 0px;
}
.home h1 span {
  font-size: 72px;
  color: #e8e8e8;
}
.home h2,
.home h2 span {
  font-size: 1.3rem;
  color: var(--link-light);
}
.home h3 {
  color: #fff;
  font-size: 22px;
}
.Typewriter {
  display: inline-block;
}
span.Typewriter__cursor {
  color: var(--color-black) !important;
}
/* Home section ends */

/* About section */
section.about,
section.services,
section.skills,
section.portfolio,
section.contact {
  padding: 60px 40px;
}
div#rotating_cube {
  width: 300px;
  margin: auto;
}
section.about p {
  max-width: 560px;
  margin: 0 auto;
}
/* About section ends */

/* Skills Section */
.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 5fr));
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 1rem;
  padding: 1rem 80px;
  font-size: 1.2rem;
}
.skills-box {
  padding: 1rem;
  color: #000;
  cursor: pointer;
}
.skills-box:hover img {
  transition: 0.3s all ease-in-out;
  transform: translateY(-20px);
}
.skills-icons {
  width: 50px;
  margin: 0 auto;
  z-index: 2;
}
.skills-box h3 {
  color: #333;
  margin-top: 0.5rem;
}
/* Skills end*/

/* Portfolio section */
section#portfolio {
  justify-content: flex-start;
}
.mak-tabs {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
.mak-tabs .tabs button.tab.tab-active {
  background: #03203c;
}
.portfolio .tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: #f1f6ff;
}
.tabs-boxed .tab {
  color: #03203c;
}
.portfolio-row {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-evenly;
}
.portfolio-item {
  padding: 0px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.5s ease-in-out;
  min-height: 200px;
  max-height: 360px;
  overflow: hidden;
  position: relative;
}
.portfolio-item:hover {
  transform: scale(1.05);
}
.portfolio-item h2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 8px;
  background: #dae6fca6;
  color: #03203c;
  min-height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oswald";
  font-weight: 700;
}
.portfolio-tabs {
  font-size: 16px;
}
.tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.tab-buttons button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 16px;
}
.tab-buttons button.active {
  background: var(--color-black);
  color: #fff;
}
.tab-content {
  display: none;
}
.active.tab-content {
  display: flex;
  /* flex-flow: column wrap; */
  gap: 20px;
  flex-wrap: wrap;
}
/* Re-order items into rows */
/* .portfolio-item:nth-child(3n + 1) {
  order: 1;
}
.portfolio-item:nth-child(3n + 2) {
  order: 2;
}
.portfolio-item:nth-child(3n) {
  order: 3;
} */

/* Force new columns */
/* .active.tab-content::before,
.active.tab-content::after {
  flex-basis: 100%;
  width: 0;
  border: 0px solid #ddd;
  margin: 0;
  content: "";
  padding: 0;
} */

#portfolio .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
}
#portfolio .popup-content {
  background: #fff;
  max-width: 720px;
  text-align: center;
  position: relative;
  height: 100vh;
}
#portfolio .popup-body {
  padding: 40px;
  max-height: 90vh;
  overflow-y: auto;
}
#portfolio .popup-content h2 {
  margin-top: 0;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
}
.wp-block-image,
.wp-block-kadence-image {
  margin: 20px auto;
}
#portfolio .popup-content button {
  background: #03203c;
  color: #fff;
  border: none;
  padding: 0px;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: -20px;
  top: -20px;
}
button svg {
  width: 20px;
  height: 20px;
  position: absolute;
}
.wp-block-button,
.kt-btn-wrap {
  margin: 15px 10px;
}
a.wp-block-button__link,
a.kt-button {
  background: #03203c;
  color: #fff;
  padding: 5px 20px;
  margin-top: 20px;
  border-radius: 30px;
}
/* Portfolio ends */

/* Contact Section */
section.contact,
section.skills {
  background-color: #f5f9ff;
}
section.contact {
  text-align: left;
}
.contact h3 {
  margin-bottom: 20px;
}
.contact p {
  padding: 6px 0;
}
.contact p a {
  color: var(--color-dark-blue);
  font-weight: 500;
}
section#contact > div {
  flex-basis: 45%;
}
.contact form {
  flex-basis: 45%;
  max-width: 600px;
  margin: 0 auto;
}
.contact form > div {
  margin-bottom: 12px;
}
.contact form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 5px;
  border: 1px solid #03203c54;
}
.contact form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 5px;
  border: 1px solid #03203c54;
  min-height: 96px;
}
/* Contact ends */

/* Footer */
.nav-footer {
  text-align: center;
}
.nav-footer ul {
  display: flex;
  justify-content: center;
}
.nav-footer ul li {
  list-style: none;
}
.nav-footer a,
.nav-footer a i,
.nav-footer p {
  color: var(--link-gray);
  padding: 10px;
}
.nav-footer p {
  font-size: 12px;
}
.nav-footer a:hover i {
  transition: 0.3s all ease-in-out;
  color: var(--link-blue);
}
/* Footer ends */

/* Responsive */
@media (max-width: 1360px) {
  #portfolio .popup-content {
    max-width: 560px;
  }
}

/* Desktops */
@media (min-width: 1024px) {
  main {
    margin-left: auto;
    width: 80%;
  }
  .drawer-side {
    width: 20%;
    position: fixed;
    z-index: 1;
  }
}

/* Tablets and Desktops */
@media (min-width: 768px) and (max-width: 1023px) {
  .drawer-side {
    width: 40%;
  }
}
@media (min-width: 768px) {
  .drawer-side header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  #portfolio .popup-content {
    height: 96vh;
    margin-top: 20px;
    border-radius: 4px;
  }
}

/* Mobile Devices */
@media (max-width: 767px) {
  .home h1,
  .home h1 span {
    font-size: 50px;
  }
  .home h2,
  .home h2 span {
    font-size: 1.1rem;
  }
  section.about,
  section.services,
  section.skills,
  section.portfolio,
  section.contact {
    padding: 50px 30px;
  }
  section.about p {
    text-align: left;
  }
  .portfolio-row {
    gap: 20px;
  }
  .portfolio-item {
    flex-basis: 45%;
    max-height: 200px;
    padding: 5px;
  }
  #portfolio .popup-content {
    margin-top: 0px;
  }
  #portfolio .popup-content button {
    left: 5px;
    top: 5px;
  }
  .btn-circle {
    height: 10px;
    width: 30px;
  }
  .skills-container {
    padding: 1rem 30px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 5fr));
  }
}

@media (max-width: 639px) {
  .contact form {
    margin: 0;
  }
}
